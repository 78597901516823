import moment from "moment";
export const generateMap = (address) => {
  return new Promise((resolve, reject) => {
    // let path = `https://earthapi.azure-api.net/earthapi/dotEarth/GenerateMap?address=${address}`;
    let path = formApiRequestPath(address);
    fetch(path)
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const formApiRequestPath = (accountAddress) => {
  let endpoint = "GenerateMap";
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let params = { address: accountAddress };
  let pathName = window.location.pathname;
  let subdomain = pathName.split("/map/")[1];
  if (urlParams.a) {
    params.address = urlParams.a;
  } else if (subdomain) {
    params.subdomain = subdomain;
    delete params.address;
    endpoint = "GenerateSubdomainMap";
  }
  // if (urlParams.subdomain) {
  //   params.subdomain = urlParams.subdomain;
  //   delete params.address;
  //   endpoint = "GenerateSubdomainMap";
  // }
  const queryParams = new URLSearchParams(params);
  let apiPath = `https://earthapi.azure-api.net/earthapi/dotEarth/${endpoint}?${queryParams.toString()}`;
  return apiPath;
};
export const uploadImage = (data) => {
  return new Promise((resolve, reject) => {
    let path = `https://earthapi.azure-api.net/earthapi/dotEarth/UploadFullMap`;
    fetch(path, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: {
        base64image: data,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generateHexPoints = (width, height, radius) => {
  const points = [0, 1, 2, 3, 4, 5, 6]
    .map((n, i) => {
      var angle_deg = 60 * i - 30;
      var angle_rad = (Math.PI / 180) * angle_deg;
      return [
        width / 2 + radius * Math.cos(angle_rad),
        height / 2 + radius * Math.sin(angle_rad),
      ];
    })
    .map((p) => p.join(","))
    .join(" ");

  return points;
};

export const generateHexSVGBase64 = (hex) => {
  return new Promise(async (resolve, reject) => {
    const { borderColorHex, borderColorWidth, fillText, fillURL, q, r, s } =
      hex;
    const width = 200;
    const height = 200;
    const radius = 100 - (borderColorWidth ?? 0);
    let points = generateHexPoints(width, height, radius);

    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    let polygon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    let defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
    let pattern = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "pattern"
    );
    let image = document.createElementNS("http://www.w3.org/2000/svg", "image");
    let text = document.createElementNS("http://www.w3.org/2000/svg", "text");

    /*SVG */
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    svg.setAttribute("width", `${width}`);
    svg.setAttribute("height", `${height}`);

    /*Polygon */
    if (borderColorWidth > 0) {
      polygon.setAttribute("stroke-width", `${borderColorWidth}`);
    }
    if (borderColorHex) {
      polygon.setAttribute("stroke", `${borderColorHex}`);
    }
    polygon.setAttribute("points", points);

    if (fillURL) {
      polygon.setAttribute("fill", "url(#image)");
    } else {
      polygon.setAttribute("fill", "#ccffcc");
    }

    /*Pattern */
    pattern.setAttribute("patternUnits", "userSpaceOnUse");
    pattern.setAttribute("id", "image");
    // pattern.setAttribute("x", "0");
    // pattern.setAttribute("y", "0");
    pattern.setAttribute("width", `${width}`);
    pattern.setAttribute("height", `${height}`);

    /*Image */
    image.setAttribute("xlink:href", `${fillURL}`);
    image.setAttribute("x", "10");
    image.setAttribute("y", "0");
    image.setAttribute("width", `${borderColorWidth ? "110%" : "100%"}`);
    image.setAttribute("height", `${borderColorWidth ? "110%" : "100%"}`);
    image.setAttribute("preserveAspectRatio", `xMidYMid slice`);

    if (fillURL) {
      try {
        let imageBase64 = await toDataUrl(fillURL);
        image.setAttribute("xlink:href", `${imageBase64}`);
      } catch (error) {
        reject(error);
        return;
      }
      pattern.appendChild(image);
      defs.appendChild(pattern);
      svg.appendChild(defs);
      svg.appendChild(polygon);
    } else {
      svg.appendChild(polygon);
    }

    if (fillText) {
      text.setAttribute("x", "50%");
      text.setAttribute("y", "50%");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("dominant-baseline", "middle");
      text.style.fill = "white";
      text.style.fontFamily = "Verdana";
      text.style.fontSize = "20";
      text.innerHTML = `${fillText}`;
      svg.appendChild(text);
    }

    const data = svg.outerHTML;
    SVGToImage({ svg: data })
      .then((pngData) => {
        resolve(pngData);
      })
      .catch((error) => {
        reject(error);
        console.log({ error });
      });
    // const base64Str = Buffer.from(data, "utf8").toString("base64");
    // let source = "data:image/svg+xml;base64," + base64Str;
    // var canvas = document.createElement("canvas");
    // var context = canvas.getContext("2d");
    // canvas.width = width;
    // canvas.height = height;
    // var imageTag = new Image();
    // imageTag.onload = function () {
    //   context.clearRect(0, 0, width, height);
    //   context.drawImage(imageTag, 0, 0, width, height);
    //   var pngData = canvas.toDataURL("image/png");
    //   console.log({ pngData });
    // };
    // imageTag.src = source;
    // console.log({ source });
    // return source;
  });
};

/**
 * Simple function that converts a plain SVG string or SVG DOM Node into an image with custom dimensions.
 *
 * @param {Object} settings The configuration object to override the default settings.
 * @see https://ourcodeworld.com/articles/read/1456/how-to-convert-a-plain-svg-string-or-svg-node-to-an-image-png-or-jpeg-in-the-browser-with-javascript
 * @returns {Promise}
 */
function SVGToImage(settings) {
  let _settings = {
    svg: null,
    // Usually all SVG have transparency, so PNG is the way to go by default
    mimetype: "image/png",
    quality: 0.92,
    width: "auto",
    height: "auto",
    outputFormat: "base64",
  };

  // Override default settings
  for (let key in settings) {
    _settings[key] = settings[key];
  }
  console.log({ _settings });

  return new Promise(function (resolve, reject) {
    let svgNode;

    // Create SVG Node if a plain string has been provided
    if (typeof _settings.svg == "string") {
      // Create a non-visible node to render the SVG string
      let SVGContainer = document.createElement("div");
      SVGContainer.style.display = "none";
      SVGContainer.innerHTML = _settings.svg;
      svgNode = SVGContainer.firstElementChild;
    } else {
      svgNode = _settings.svg;
    }

    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");

    let svgXml = new XMLSerializer().serializeToString(svgNode);
    let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

    const image = new Image();

    image.onload = function () {
      let finalWidth, finalHeight;

      // Calculate width if set to auto and the height is specified (to preserve aspect ratio)
      if (_settings.width === "auto" && _settings.height !== "auto") {
        finalWidth = (this.width / this.height) * _settings.height;
        // Use image original width
      } else if (_settings.width === "auto") {
        finalWidth = this.naturalWidth;
        // Use custom width
      } else {
        finalWidth = _settings.width;
      }

      // Calculate height if set to auto and the width is specified (to preserve aspect ratio)
      if (_settings.height === "auto" && _settings.width !== "auto") {
        finalHeight = (this.height / this.width) * _settings.width;
        // Use image original height
      } else if (_settings.height === "auto") {
        finalHeight = this.naturalHeight;
        // Use custom height
      } else {
        finalHeight = _settings.height;
      }

      // Define the canvas intrinsic size
      canvas.width = finalWidth;
      canvas.height = finalHeight;

      // Render image in the canvas
      context.drawImage(this, 0, 0, finalWidth, finalHeight);

      if (_settings.outputFormat == "blob") {
        // Fullfil and Return the Blob image
        canvas.toBlob(
          function (blob) {
            resolve(blob);
          },
          _settings.mimetype,
          _settings.quality
        );
      } else {
        // Fullfil and Return the Base64 image
        resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
      }
    };

    // Load the SVG in Base64 to the image
    image.src = svgBase64;
  });
}

function toDataUrl(url) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        console.log({ reader });
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.responseType = "blob";
    xhr.send();
  });
}

export const HexFilters = {
  hours: "24 Hours",
  week: "Week",
  month: "Month",
  all: "All",
  home: "Home",
  nft: "NFT",
  defi: "Defi",
  treasury: "Treasury",
  bigMoves: "BigMoves",
  ens: "ENS",
};
export const TimeFilters = {
  all: "All",
  hours: "24 Hours",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
};
export const filterByTime = (data, sort) => {

  let filtered = data.filter((hex) => {
    const { isLast7, isLast24, isLast31, isLastQu } = hex;
    if (sort === TimeFilters.hours) {
      return isLast24 === 1;
    } else if (sort === TimeFilters.week) {
      return isLast7 === 1;
    } else if (sort === TimeFilters.month) {
      return isLast31 === 1;
    } else if (sort === TimeFilters.quarter) {
      return isLastQu === 1;
    }
    return true;
  });

  return filtered;
};

export const filterByHexType = (data, filter, sort) => {

  let filtered = data.filter((hex) => {
    const { isLargeTxn, glyphType, isLatest, isENS , isSubENS } = hex;

    if (filter === HexFilters.home) {
      return isLatest === 1;
    } else if (filter === HexFilters.nft) {
      return glyphType === 1 || glyphType === 2;
    } else if (filter === HexFilters.defi) {
      return glyphType === 3;
    } else if (filter === HexFilters.treasury) {
      return glyphType === 4 || glyphType === 5;
    } else if (filter === HexFilters.bigMoves) {
      return isLargeTxn === 1;
    } else if (filter === HexFilters.ens) {
      return isENS === 1 || isSubENS === 1  ;
    } 
    return true;
  });

  return filtered;
};


export const filterByPageSize = (hexes,pageSize) => {
  return hexes.slice(0,pageSize);
}

export const getGlyphInfo = (id) => {
  return fetch(`https://api.earth.domains/earthapi/dotEarth/GetGlyphDetail?glyphExtId=${id}`).then(res => res.json());
};