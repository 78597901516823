import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
export const ConnectWalletModal = ({
  open,
  handleClose,
  onClickMetaMask,
  onClickWalletConnect,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        className="earth-model"
      >
        <DialogTitle className="flx-end">
          <Button onClick={handleClose} className="close-btn">
            {" "}
            <span>X</span>{" "}
          </Button>
        </DialogTitle>
        <DialogContent>
          <section className="flx flx-align-center flx-just-cont-center flx-dir-colm">
            <h3>Connecting your wallet</h3>
            <h4>
              Connect with one of available wallet providers or create a new
              wallet
            </h4>
            {/* <h5> <a href="">Terms of Service </a>  and our <a href="">Privacy Policy</a></h5> */}
            <ul className="wallet-block">
              <li className="primary-btn">
                <Button
                  variant="outlined"
                  className="metaMask text-black"
                  onClick={onClickMetaMask}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="92"
                      height="91"
                      viewBox="0 0 92 91"
                      fill="none"
                    >
                      <ellipse
                        cx="46.0003"
                        cy="45.0778"
                        rx="45.639"
                        ry="45.0778"
                        fill="#F6851B"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M65.597 25L48.6465 37.5767L51.7949 30.1566L65.597 25Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.5059 25L43.3032 37.6959L40.3079 30.1566L26.5059 25Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M59.505 54.1528L54.9951 61.0624L64.6447 63.7173L67.4017 54.306L59.505 54.1528Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.7188 54.306L27.4587 63.7173L37.1083 61.0624L32.6154 54.1528L24.7188 54.306Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M36.5805 42.478L33.8916 46.5455L43.4561 46.9709L43.1327 36.6747L36.5805 42.478Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M55.522 42.4781L48.8677 36.5557L48.6465 46.971L58.2109 46.5456L55.522 42.4781Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M37.1084 61.0623L42.8777 58.2543L37.9083 54.374L37.1084 61.0623Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M49.2256 58.2543L54.9949 61.0623L54.195 54.374L49.2256 58.2543Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M54.9949 61.0623L49.2256 58.2543L49.6851 62.0154L49.634 63.5981L54.9949 61.0623Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M37.1084 61.0623L42.4693 63.5981L42.4352 62.0154L42.8777 58.2543L37.1084 61.0623Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M42.5707 51.8895L37.7715 50.4769L41.1582 48.9282L42.5707 51.8895Z"
                        fill="#233447"
                        stroke="#233447"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M49.5312 51.8895L50.9438 48.9282L54.3475 50.4769L49.5312 51.8895Z"
                        fill="#233447"
                        stroke="#233447"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M37.1081 61.0624L37.9421 54.1528L32.6152 54.306L37.1081 61.0624Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M54.1611 54.1528L54.995 61.0624L59.505 54.306L54.1611 54.1528Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M58.2109 46.5455L48.6465 46.971L49.5315 51.8894L50.944 48.9281L54.3477 50.4768L58.2109 46.5455Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M37.7718 50.4768L41.1585 48.9281L42.5711 51.8894L43.4561 46.971L33.8916 46.5455L37.7718 50.4768Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M33.8916 46.5455L37.908 54.3741L37.7718 50.4768L33.8916 46.5455Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M54.3485 50.4768L54.1953 54.3741L58.2117 46.5455L54.3485 50.4768Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M43.4563 46.9709L42.5713 51.8893L43.6945 57.6927L43.9328 50.0513L43.4563 46.9709Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M48.647 46.9709L48.1875 50.0343L48.4087 57.6927L49.532 51.8893L48.647 46.9709Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M49.5314 51.8894L48.4082 57.6927L49.2251 58.2543L54.1945 54.3741L54.3477 50.4768L49.5314 51.8894Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M37.7715 50.4768L37.9076 54.3741L42.8771 58.2543L43.694 57.6927L42.5707 51.8894L37.7715 50.4768Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M49.6341 63.5981L49.6851 62.0154L49.2597 61.641H42.8437L42.4352 62.0154L42.4693 63.5981L37.1084 61.0624L38.9804 62.5941L42.7926 65.2319H49.3107L53.1229 62.5941L54.9949 61.0624L49.6341 63.5981Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M49.226 58.2544L48.4091 57.6927H43.6949L42.878 58.2544L42.4355 62.0155L42.844 61.6411H49.26L49.6855 62.0155L49.226 58.2544Z"
                        fill="#161616"
                        stroke="#161616"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M66.3123 38.3936L67.7418 31.45L65.5975 25L49.2256 37.1513L55.5225 42.4781L64.4232 45.0819L66.3803 42.7844L65.5294 42.1718L66.8909 40.9294L65.8528 40.1125L67.2142 39.0744L66.3123 38.3936Z"
                        fill="#763D16"
                        stroke="#763D16"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.3613 31.45L25.8079 38.3936L24.8889 39.0744L26.2504 40.1125L25.2123 40.9294L26.5737 42.1718L25.7228 42.7844L27.68 45.0819L36.5807 42.4781L42.8776 37.1513L26.5057 25L24.3613 31.45Z"
                        fill="#763D16"
                        stroke="#763D16"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M64.4235 45.0819L55.5228 42.478L58.2117 46.5455L54.1953 54.374L59.5051 54.306H67.4017L64.4235 45.0819Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M36.5807 42.478L27.68 45.0819L24.7188 54.306H32.6154L37.9082 54.374L33.8918 46.5455L36.5807 42.478Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M48.6467 46.971L49.2254 37.1513L51.7952 30.1566H40.3076L42.8774 37.1513L43.4561 46.971L43.6773 50.0684L43.6943 57.6927H48.4085L48.4255 50.0684L48.6467 46.971Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  MetaMask
                  <span className="sub-head">
                    Connect to your MetaMask Wallet
                  </span>
                </Button>
              </li>
              <li className="primary-btn">
                <Button
                  variant="outlined"
                  className="wallet-connect text-black"
                  onClick={onClickWalletConnect}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="92"
                      height="91"
                      viewBox="0 0 92 91"
                      fill="none"
                    >
                      <ellipse
                        cx="46.0003"
                        cy="45.2335"
                        rx="45.639"
                        ry="45.0778"
                        fill="#3C99FC"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M31.011 35.427C39.4889 27.1436 53.2344 27.1436 61.7123 35.427L62.7327 36.4239C63.1566 36.8381 63.1566 37.5096 62.7327 37.9237L59.2423 41.334C59.0303 41.5411 58.6867 41.5411 58.4748 41.334L57.0707 39.9621C51.1562 34.1834 41.5671 34.1834 35.6526 39.9621L34.1489 41.4313C33.937 41.6384 33.5934 41.6384 33.3814 41.4313L29.8911 38.021C29.4672 37.6069 29.4672 36.9354 29.8911 36.5212L31.011 35.427ZM68.9307 42.4798L72.0371 45.5149C72.461 45.9291 72.461 46.6006 72.0371 47.0147L58.0301 60.7005C57.6062 61.1147 56.9189 61.1147 56.495 60.7006C56.495 60.7005 56.495 60.7005 56.495 60.7005L46.5536 50.9873C46.4476 50.8838 46.2758 50.8838 46.1698 50.9873C46.1698 50.9873 46.1698 50.9873 46.1698 50.9873L36.2286 60.7005C35.8048 61.1147 35.1175 61.1147 34.6936 60.7006C34.6936 60.7006 34.6936 60.7006 34.6936 60.7005L20.6861 47.0145C20.2622 46.6004 20.2622 45.9289 20.6861 45.5147L23.7925 42.4796C24.2164 42.0654 24.9037 42.0654 25.3276 42.4796L35.2691 52.1929C35.3751 52.2965 35.5469 52.2965 35.6529 52.1929C35.6529 52.1929 35.6529 52.1929 35.6529 52.1929L45.5939 42.4796C46.0178 42.0654 46.7051 42.0654 47.129 42.4795C47.129 42.4796 47.129 42.4796 47.129 42.4796L57.0705 52.1929C57.1765 52.2965 57.3483 52.2965 57.4543 52.1929L67.3957 42.4798C67.8195 42.0656 68.5068 42.0656 68.9307 42.4798Z"
                          fill="#3C99FC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="52"
                            height="32"
                            fill="white"
                            transform="translate(20.3613 29.1556)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  WalletConnect
                  <span className="sub-head">
                    {" "}
                    Scan with WalletConnect to connect{" "}
                  </span>
                </Button>
              </li>
            </ul>
            {/* <h4>New to Ethereum</h4>
              <h5> <a href=""> Learn about wallets </a></h5> */}
          </section>
        </DialogContent>
        {/* <DialogActions>
          
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};
