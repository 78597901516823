import { HexFilters, TimeFilters } from "../../index.service";

export const getCoordinates = (selectedFilter, hexData, selectedTimeFilter) => {
  const {
    q,
    r,
    s,
    qDeFi,
    qTreas,
    qbm,
    qnft,
    qens,
    q7,
    q24,
    q31,
    qQu,
    rDeFi,
    rTreas,
    rbm,
    rnft,
    rens,
    r7,
    r24,
    r31,
    rQu,
    sDeFi,
    sTreas,
    sbm,
    snft,
    sens,
    s7,
    s24,
    s31,
    sQu,
  } = hexData;
  let coordinateQ = q;
  let coordinateR = r;
  let coordinateS = s;
  switch (selectedFilter) {
    case HexFilters.bigMoves:
      coordinateQ = qbm;
      coordinateR = rbm;
      coordinateS = sbm;
      break;
    case HexFilters.defi:
      coordinateQ = qDeFi;
      coordinateR = rDeFi;
      coordinateS = sDeFi;
      break;
    case HexFilters.nft:
      coordinateQ = qnft;
      coordinateR = rnft;
      coordinateS = snft;
      break;
    case HexFilters.treasury:
      coordinateQ = qTreas;
      coordinateR = rTreas;
      coordinateS = sTreas;
      break;
    case HexFilters.ens:
      coordinateQ = qens;
      coordinateR = rens;
      coordinateS = sens;
      break;
    default:
      coordinateQ = q;
      coordinateR = r;
      coordinateS = s;
      break;
  }
  if (selectedTimeFilter && selectedTimeFilter !== TimeFilters.all) {
    switch (selectedTimeFilter) {
      case TimeFilters.hours:
        coordinateQ = q24;
        coordinateR = r24;
        coordinateS = s24;
        break;
      case TimeFilters.month:
        coordinateQ = q31;
        coordinateR = r31;
        coordinateS = s31;
        break;
      case TimeFilters.week:
        coordinateQ = q7;
        coordinateR = r7;
        coordinateS = s7;
        break;
      case TimeFilters.quarter:
        coordinateQ = qQu;
        coordinateR = rQu;
        coordinateS = sQu;
        break;
      default:
        coordinateQ = q;
        coordinateR = r;
        coordinateS = s;
        break;
    }
  }

  return { q: coordinateQ, r: coordinateR, s: coordinateS };
};
