import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ChainId } from "./web3-constants";

const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/b88f01512f6a4fad8f8ff6a7c26670fb",
};

const injected = new InjectedConnector({
  supportedChainIds: [
    ChainId.ethereumMainnet,
    ChainId.ropstenTestNet,
    ChainId.rinkebyTestNet,
    ChainId.goerliTestNet,
    ChainId.kovanTestNet,
  ],
});

const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/b88f01512f6a4fad8f8ff6a7c26670fb`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
};
