import React, { useEffect, useState } from "react";
import DashboardContainer from "./container";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../web3/connectors";
import { ChainId, Providers } from "../../web3/web3-constants";
import {
  filterByHexType,
  HexFilters,
  generateMap,
  TimeFilters,
} from "./index.service";

import GlobalContext from "./context/global.context";

const DashboardPage = () => {
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [hexData, setHexData] = useState({});
  const [filteredHexData, setFilteredHexData] = useState({});
  const [totalPageNumberCount, setTotalPageNumberCount] = useState(0);
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [twitterImageUrl, setTwitterImageUrl] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(HexFilters.home);
  const [isUrlParamAndWalletNotConnected, setIsUrlParamAndWalletNotConnected] =
    useState(false);
  const [pageSizeNumber, setPageSizeNumber] = useState(0);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(TimeFilters.all);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginateDirection, setPaginateDirection] = useState("left");
  const [hexPanelOpen, setHexPanelOpen] = useState(false);
  const [currentGlyphId, setCurrentGlphyId] = useState(null);

  /* Use Effect */
  useEffect(() => {
    let provider = window.localStorage.getItem("provider");
    if (typeof provider === "string") {
      provider = null;
    }
    if (provider) {
      activate(connectors[provider]);
    } else {
      _checkForParamOrSubDomain();
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
    let hexes = hexData?.hexes ?? [];
    if (hexes.length > 0) {
      let filteredData = filterByHexType(
        hexes,
        selectedFilter,
        selectedTimeFilter
      );
      //let filterByTimeData = filterByTime(filteredData, selectedTimeFilter);
      let filterByTimeData = filteredData;
      if (filterByTimeData.length > 0) {
        if (filterByTimeData.length % pageSizeNumber > 0) {
          setTotalPageNumberCount(
            Math.floor(filterByTimeData.length / pageSizeNumber) + 1
          );
        } else {
          setTotalPageNumberCount(
            Math.floor(filterByTimeData.length / pageSizeNumber)
          );
        }
        let currentPageNumberData = _getCurrentPageNumberData(
          selectedFilter,
          filterByTimeData
        );
        if (currentPageNumberData.length > 0)
          setFilteredHexData({ ...hexData, hexes: currentPageNumberData });
      }
    }
  }, [selectedFilter, hexData, pageSizeNumber]);

  useEffect(() => {
    if (hexData) {
      const hexes = hexData.hexes || [];
      let filteredData = filterByHexType(
        hexes,
        selectedFilter,
        selectedTimeFilter
      );
      let filterByTimeData = filteredData;
      if (filterByTimeData.length > 0) {
        let currentPageNumberData = _getCurrentPageNumberData(
          selectedFilter,
          filterByTimeData
        );
        setFilteredHexData({ ...hexData, hexes: currentPageNumberData });
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    if (account) {
      _queryHex(account);
    }
    setIsValidNetwork(chainId === ChainId.ethereumMainnet);
  }, [library, chainId, account, active]);

  console.log(active);

  /* Public Functions */
  const onClickHexDetail = (hex) => {
    if(hex.glyphType === 5){
      setCurrentGlphyId(hex.guid);
      setHexPanelOpen(true);
    }
  };
  const onClickConnectWallet = (e) => {
    e.preventDefault();
    setShowConnectModal(true);
  };
  const openShareModal = () => {
    setShowShareModal(true);
  };
  const closeConnectWalletModal = () => {
    setShowConnectModal(false);
  };
  const closeShareModal = () => {
    setTwitterImageUrl("");
    setShowShareModal(false);
  };
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };
  const onClickMetaMask = () => {
    closeConnectWalletModal();
    activate(connectors.injected);
    setProvider(Providers.metaMask);
  };
  const onClickWalletConnect = () => {
    closeConnectWalletModal();
    activate(connectors.walletConnect);
    setProvider(Providers.walletConnect);
  };
  const onClickDisconnect = () => {
    window.localStorage.setItem("provider", undefined);
    document.body.className = "";
    deactivate();
    setHexData({});
    setIsValidNetwork(false);
  };
  const onClickShareOnTwitter = async (hex) => {
    const { fillURL } = hex;
    setTwitterImageUrl(fillURL);
    setShowShareModal(true);
    return;
  };
  const onChangeFilter = (event) => {
    if (event instanceof Event) {
      setSelectedFilter(event.target.value);
    } else {
      setSelectedFilter(event);
    }
  };
  const handlePaging = (type) => {
    setPaginateDirection(type);
    if (type === "right") {
      _handlePagingRight();
      return;
    }
    _handlePagingLeft();
  };
  const moveGlyph = (type) => {
    if (type === "left") {
      _handleMoveGlyphLeft();
      return;
    }
    _handleMoveGlyphRight();
  };

  /* Private Functions */
  const _handleMoveGlyphLeft = () => {
    const { hexes } = filteredHexData;
    const currentIndex = hexes.findIndex((hex) => hex.guid === currentGlyphId);
    const nextHex = hexes[currentIndex - 1];
    if (nextHex) {
      const guid = nextHex.guid;
      setCurrentGlphyId(guid);
    }
  };
  const _handleMoveGlyphRight = () => {
    const { hexes } = filteredHexData;
    const currentIndex = hexes.findIndex((hex) => hex.guid === currentGlyphId);
    const nextHex = hexes[currentIndex + 1];
    if (nextHex) {
      const guid = nextHex.guid;
      setCurrentGlphyId(guid);
    }
  };
  const _handlePagingLeft = () => {
    setPageNumber((pageNumber) => {
      if (pageNumber === 0) {
        return 0;
      }
      return pageNumber - 1;
    });
  };
  const _handlePagingRight = () => {
    setPageNumber(pageNumber + 1);
  };
  const _getCurrentPageNumberData = (selectedFilter, filterByTimeData) => {
    switch (selectedFilter) {
      case HexFilters.home:
        return filterByTimeData.filter((hex) => hex["p"] === pageNumber);
      case HexFilters.nft:
        return filterByTimeData.filter((hex) => hex["pnft"] === pageNumber);
      case HexFilters.ens:
        return filterByTimeData.filter((hex) => hex["pens"] === pageNumber);
      case HexFilters.defi:
        return filterByTimeData.filter((hex) => hex["pDeFi"] === pageNumber);
      case HexFilters.treasury:
        return filterByTimeData.filter((hex) => hex["pTreas"] === pageNumber);
      case HexFilters.bigMoves:
        return filterByTimeData.filter((hex) => hex["pbm"] === pageNumber);
      default:
        return filterByTimeData;
    }
  };
  const _queryHex = async (account) => {
    if (account) {
      setIsLoading(true);
      generateMap(account)
        .then((response) => {
          setIsLoading(false);
          setHexData(response[0]);
          console.log(response);
          setPageSizeNumber(response[0].hexGridPageSize || 20);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };
  const _checkForParamOrSubDomain = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("a")) {
      setIsUrlParamAndWalletNotConnected(true);
      _queryHex(params.get("a"));
    } else {
      let pathName = window.location.pathname;
      let subdomain = pathName.split("/map/")[1];
      if (subdomain) {
        _queryHex("sample");
        setIsUrlParamAndWalletNotConnected(true);
      }
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        paginateDirection,
        pageNumber,
        totalPageNumberCount,
        openShareModal,
        active,
        account,
        onClickDisconnect,
        isValidNetwork,
        onClickMetaMask,
        isLoading,
        isUrlParamAndWalletNotConnected,
        hexData,
        library,
        hexPanelOpen,
        currentGlyphId,
        setHexPanelOpen,
        moveGlyph,
      }}
    >
      <DashboardContainer
        handlePaging={handlePaging}
        connectToWallet={onClickMetaMask}
        isUrlParamAndWalletNotConnected={isUrlParamAndWalletNotConnected}
        onClickConnectWallet={onClickConnectWallet}
        closeConnectWalletModal={closeConnectWalletModal}
        onClickMetaMask={onClickMetaMask}
        onClickWalletConnect={onClickWalletConnect}
        showConnectModal={showConnectModal}
        showShareModal={showShareModal}
        closeShareModal={closeShareModal}
        onClickDisconnect={onClickDisconnect}
        accountConnected={active}
        address={account}
        hexData={filteredHexData}
        isValidNetwork={isValidNetwork}
        isLoading={isLoading}
        onClickHex={onClickHexDetail}
        twitterImageUrl={twitterImageUrl}
        onChangeFilter={onChangeFilter}
        selectedFilter={selectedFilter}
        hexPanelOpen={hexPanelOpen}
        currentGlyphId={currentGlyphId}
      />
    </GlobalContext.Provider>
  );
};

export default DashboardPage;
