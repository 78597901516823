import React from "react";
import "../paginate/index.css";
import { RenderIf } from "../../../../util/renderIf";
import rightArrow from "../../../../images/pagination/right_arrow.svg";
import leftArrow from "../../../../images/pagination/left_arrow.svg";

const PaginateArrow = ({ isRight, handleClick, isDisabled }) => {
  /* Variables */
  const ARROW_CLASSES = `h-10 w-10 ${isDisabled ? "disabled-opacity" : ""} cursor-pointer`;

  /* Private Functions */
  function _handleClick() {
    if (!isDisabled) handleClick();
  }

  /* JSX Elements */
  function RightArrow() {
    return (
      <img
        onClick={_handleClick}
        className={ARROW_CLASSES}
        src={rightArrow}
        alt={"Right Arrow"}
      />
    );
  }

  function LeftArrow() {
    return (
      <img
        onClick={_handleClick}
        className={ARROW_CLASSES}
        src={leftArrow}
        alt={"Left Arrow"}
      />
    );
  }

  return (
    <div className="width-100 pagination-wrapper">
      <RenderIf isTrue={isRight}>
        <RightArrow />
      </RenderIf>
      <RenderIf isTrue={!isRight}>
        <LeftArrow />
      </RenderIf>
   
    </div>
  );
};

export default PaginateArrow;
