import "../css/hex-detail-panel.css";
import hexdetailoader from '../../../../../images/hexdetailloader.svg';

const HexDetailLoader = () => {
  return (
    <div className="animate-pulse">
    <img src={hexdetailoader} alt={'loader'} />
    </div>

  );
};

export default HexDetailLoader;
