import React, { useState, memo , useEffect } from "react";
import Button from "@mui/material/Button";
import { ConnectWalletModal } from "./component/connect-wallet-modal";
import { ShareModal } from "./component/share-modal";
import Snackbar from "@mui/material/Snackbar";
import { RenderIf } from "../../util/renderIf";
import ContainerSidebar from "./component/container-layouts/container-sidebar/contaner-sidebar";
import ContainerContent from "./component/container-layouts/container-content/container-content";
import logo from "../../images/logo.svg";
import ContainerSidebarFooter from "./component/container-layouts/container-sidebar/footer/container-sidebar-footer";
import defi from "../../images/newicons/defi.svg";
import ens from "../../images/newicons/ens.svg";
import nft from "../../images/newicons/nft.svg";
import treasury from "../../images/newicons/treasury.svg";
import { HexFilters } from "./index.service";

const DashboardContainer = ({
  onClickConnectWallet,
  closeConnectWalletModal,
  onClickMetaMask,
  onClickWalletConnect,
  showConnectModal,
  showShareModal,
  closeShareModal,
  accountConnected,
  hexData,
  isValidNetwork,
  onClickHex,
  twitterImageUrl,
  onChangeFilter,
  selectedFilter,
  handlePaging,
  isUrlParamAndWalletNotConnected,
  active,
  hexPanelOpen,
  currentGlyphId,
}) => {
  /* Use State Hook */
  const [hexTypeList, setHexTypes] = useState([
    {
      id: 1,
      title: "Collectibles",
      img: nft,
      active: false,
      filter: HexFilters.nft,
    },
    {
      id: 2,
      title: "ENS",
      img: ens,
      active: false,
      filter: HexFilters.ens,
    },
    {
      id: 3,
      title: "Defi",
      img: defi,
      active: false,
      filter: HexFilters.defi,
    },
    {
      id: 4,
      title: "Treasury",
      img: treasury,
      active: false,
      filter: HexFilters.treasury,
    },
  ]);
  const [isHomeSelected, setHomeSelected] = useState(true);
  const [isSearchPanel, setSearchPanel] = useState(false);

  useEffect(() => {
    if (isHomeSelected === true) {
      closeSearchPanel();
    }
  }, [isHomeSelected]);

  /* JSX Functions */
  const NotConnectedView = () => {
    return (
      <div className="flex flex-col  gap-4 h-screen p-4 bg-not-connected-view">
        <div className="flex w-full justify-between flex-1  items-start">
          <img src={logo} alt={"logo"} className={"h-20 w-20"}></img>
          <span
            className="tracking-wide underline cursor-pointer"
            onClick={onClickConnectWallet}
          >
            Connect your wallet
          </span>
        </div>

        <div className="flex flex-col justify-center items-center gap-7 flex-1">
          <h1 className="text-[80px]">ATLAS. </h1>
          <p className="text-xl">Map your Web3 Presence. </p>
          <aside className="primary-btn">
            <Button variant="contained" onClick={onClickConnectWallet}>
              <span>CONNECT YOUR WALLET</span>
            </Button>
          </aside>
        </div>

        <div className="flex flex-1">
          <ContainerSidebarFooter />
        </div>
      </div>
    );
  };

  const ConnectedView = () => {
    return (
      <div className="flex flex-row  h-screen justify-start">
        <div className="flex w-[100px]">
          <ContainerSidebar
            hexData={hexData}
            hexTypeList={hexTypeList}
            onChangeFilter={onChangeFilter}
            isHomeSelected={isHomeSelected}
            setHomeSelected={setHomeSelected}
            openSearchPanel={openSearchPanel}
            isSearchPanel={isSearchPanel}
          />
        </div>
        <div className="flex w-full ">
          <RenderIf isTrue={!isSearchPanel}>
            <ContainerContent
              showShareModal={showShareModal}
              onClickConnectWallet={onClickConnectWallet}
              hexData={hexData}
              onClickHex={onClickHex}
              onChangeFilter={onChangeFilter}
              selectedFilter={selectedFilter}
              handlePaging={handlePaging}
              accountConnected={accountConnected}
              hexPanelOpen={hexPanelOpen}
              currentGlyphId={currentGlyphId}
            />
          </RenderIf>
          <RenderIf isTrue={isSearchPanel}>
            <div className="w-full h-full bg-slate-200 flex justify-center items-center">
              <div className="flex h-[70px] drop-shadow ">
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Search name or address"
                    className="h-full w-[600px] py-3 px-5 focus:outline-none"
                  ></input>
                </div>
                <div className="py-3 bg-backgroundDropdown px-5 flex items-center justify-center">
                  Search
                </div>
              </div>
            </div>
          </RenderIf>
        </div>
      </div>
    );
  };

  /* Public */
  const openSearchPanel = () => {
    setSearchPanel(true);
    setHomeSelected(false);
  };

  const closeSearchPanel = () => {
    setSearchPanel(false);
  };

  return (
    <React.Fragment>
      <RenderIf isTrue={accountConnected || isUrlParamAndWalletNotConnected}>
        <ConnectedView />
      </RenderIf>

      <RenderIf
        isTrue={
          Object.keys(hexData).length === 0 &&
          !active &&
          !isUrlParamAndWalletNotConnected
        }
      >
        <NotConnectedView />
      </RenderIf>

      <ConnectWalletModal
        open={showConnectModal}
        handleClose={closeConnectWalletModal}
        onClickMetaMask={onClickMetaMask}
        onClickWalletConnect={onClickWalletConnect}
      />
      <ShareModal
        open={showShareModal}
        handleClose={closeShareModal}
        shareUrl={twitterImageUrl}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={accountConnected && !isValidNetwork}
        onClose={() => {}}
        message="Please switch to Ethereum Mainnet"
      />
    </React.Fragment>
  );
};

export default memo(DashboardContainer);
