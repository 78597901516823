import ContainerSidebarHeader from "./header/container-sidebar-header";
import ContainerSidebarContent from "./content/container-sidebar-content";
import ContainerSidebarFooter from "./footer/container-sidebar-footer";
import "./container-sidebar.css";
import { RenderIf } from "../../../../../util/renderIf";
import GlobalContext from "../../../context/global.context";
import { useContext } from "react";

import SidebarLoader from "../../sidebar-loader/sidebar-loader";
const ContainerSidebar = ({
  onChangeFilter,
  hexData,
  hexTypeList,
  isHomeSelected,
  setHomeSelected,
  openSearchPanel,
  isSearchPanel,
}) => {
  const { isLoading } = useContext(GlobalContext);

  const selectActiveHexType = (index, filter) => {
    hexTypeList.forEach((type) => (type.active = false));
    hexTypeList[index].active = true;
    onChangeFilter(filter);
    setHomeSelected(false);
  };

  const selectHome = (filter) => {
    setHomeSelected(true);
    hexTypeList.forEach((type) => (type.active = false));
    onChangeFilter(filter);
  };

  return (
    <div className="w-full rounded-[20px] p-4 relative ">
      <RenderIf isTrue={isLoading}>
        <div className="w-full flex justify-center h-full items-center">
          <SidebarLoader />
        </div>
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <ContainerSidebarHeader hexData={hexData} />
          <ContainerSidebarContent
            openSearchPanel={openSearchPanel}
            selectHome={selectHome}
            hexTypeList={hexTypeList}
            selectActiveHexType={selectActiveHexType}
            isHomeSelected={isHomeSelected}
            isSearchPanel={isSearchPanel}
          />
        <ContainerSidebarFooter />
      </RenderIf>
    </div>
  );
};

export default ContainerSidebar;
