import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { TwitterShareButton, TwitterIcon } from "next-share";
export const ShareModal = ({
  open,
  handleClose,
  shareUrl,
  onClickWalletConnect,
}) => {
  const getUrl = () => {
    let message = `Tweet! I have generated my Atlas Map. Here is my snap: ${shareUrl} \n\n Would you want to create a similar Map. Checkout Atlas https://thankful-ocean-088da2110.1.azurestaticapps.net/dashboard`;
    return message;
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        className="earth-model"
      >
        <DialogTitle className="flx-end">
          <Button onClick={handleClose} className="close-btn">
            {" "}
            <span>X</span>{" "}
          </Button>
        </DialogTitle>
        <DialogContent>
          <section className="flx flx-align-center flx-just-cont-center flx-dir-colm">
            <h3>Share your story</h3>
            <h4>Would you like to share this transaction on Twitter?</h4>
            {/* <h5> <a href="">Terms of Service </a>  and our <a href="">Privacy Policy</a></h5>
            
            <h4>New to Ethereum</h4>
              <h5> <a href=""> Learn about wallets </a></h5> */}
          </section>
          <DialogActions className="share-btns">
            <div className="secondary-btn">
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div className="primary-btn">
              <TwitterShareButton url={""} title={getUrl()}>
                <Button variant="contained" onClick={handleClose}>
                  Confirm
                </Button>
              </TwitterShareButton>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
