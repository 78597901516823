import discord from "../../../../../../images/social/discord.svg";
import twitter from "../../../../../../images/social/twitter.svg";

const ContainerSidebarFooter = () => {
  const _navigateToUrl = (path) => {
    window.open(path, "_blank");
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="absolute flex gap-5 bottom-5 flex-col justify-center">
        <img
          src={discord}
          alt={"discord"}
          className={"cursor-pointer"}
          onClick={() => _navigateToUrl("https://discord.com/invite/dotearth")}
        />
        <img
          src={twitter}
          alt={"twitter"}
          className={"cursor-pointer"}
          onClick={() => _navigateToUrl("https://twitter.com/dotearth_")}
        />
      </div>
    </div>
  );
};

export default ContainerSidebarFooter;
