import "./App.css";
import { MainRouter } from "./router";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MainRouter />
    </Web3ReactProvider>
  );
}

export default App;
