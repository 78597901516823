import { useState } from "react";
import { useEffect, useContext } from "react";
import { RenderIf } from "../../../../util/renderIf";
import { getGlyphInfo } from "../../index.service";
import GlobalContext from "../../context/global.context";
import HexDetailLoader from "./loader/hex-detail-panel-loader";
import "./css/hex-detail-panel.css";
import { format } from 'date-fns';

const HexDetailPanel = ({ glyphId , hexData }) => {
  /* Use States */
  const [detailPanelInfo, setDetailPanelInfo] = useState(null);
  const [isLoader, setLoader] = useState(false);

  /* Global Context */
  const { setHexPanelOpen, moveGlyph  } = useContext(GlobalContext);

  /* All Use Effects */
  useEffect(() => {
     _getGlyphInformation(glyphId);
  }, [glyphId]);

  /* Private functions */
  const _getGlyphInformation = async (glyphId) => {
    console.log(glyphId); 
    const hexes = hexData.hexes;
    const hexDataObj = hexes.find(hex => hex.guid === glyphId);
    setDetailPanelInfo(hexDataObj.detail);
  };

  /* JSX Elements */
  const GlyphSharing = () => {
    return (
      <div className="flex justify-between gap-4 items-center w-full">
        <GlyphClose />
        <div className="flex gap-4 items-center">
          <div className="flex cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
              />
            </svg>
          </div>
          <div className="flex cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  const GlyphRightArrow = () => {
    return (
      <div onClick={() => moveGlyph("left")} className="cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#000"
          className="w-10 h-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    );
  };
  const GlyphLeftArrow = () => {
    return (
      <div onClick={() => moveGlyph("right")} className="cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#000"
          className="w-10 h-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    );
  };
  const GlyphClose = () => {
    return (
      <div className="cursor-pointer" onClick={() => setHexPanelOpen(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#000"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    );
  };

  const GlyphPhoto = () => {
    return (
      <div className="flex w-full justify-between items-center mb-5 ">
        <div className="flex">
          <GlyphRightArrow />
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            width={"230px"}
            className="position right-5 mb-4"
            src={
              detailPanelInfo.glyphURL
            }
            alt={"sample"}
          ></img>
          {/* <span className="text-blue-500">TRANSFER OUT <span className="text-black">{detailPanelInfo.cValue}</span></span> */}
        </div>
        <div className="flex">
          <GlyphLeftArrow />
        </div>
      </div>
    );
  };
  const GlyphInnerInfo = () => {
    return (
      <div className="flex  text-[14px] rounded">
        {/* <div className="flex flex-col justify-between items-center py-5">
          <div className="bg-white w-4 h-5 rounded-full justify-center items-center flex">
            <div className=" w-2 h-2 rounded-full bg-gradientDetailFirst"></div>
          </div>
          <div className="bg-white w-1 h-full"></div>
          <div className="bg-white w-4 h-5 rounded-full justify-center items-center flex">
            <div className=" w-2 h-2 rounded-full bg-gradientDetailSecond"></div>
          </div>
        </div> */}
        <div className="flex flex-col ">
          <div className="flex flex-col gap-2  p-4  relative bg-white border-b border-solid border-[#ddd]">
            <div className="flex">
              From
            </div>
            <span className="text-[#666]">
              {detailPanelInfo.sAddress}
            </span>
          </div>
          <div className="flex flex-col gap-2  p-4  relative bg-white ">
            <div className="flex">
              To
            </div>
            <span className="text-[#666]">
            {detailPanelInfo.tAddress}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const GlyphUpper = () => {
    return (
      <div className="p-7 rounded-3xl rounded-b-none bg-[#ededed]">
        <GlyphSharing />
        <GlyphPhoto />
        <GlyphInnerInfo />
      </div>
    );
  };
  const GlyphListItem = ({ label, value }) => {
    return (
      <li className="flex w-full p-4 px-7 border-b border-[#ddd] border-solid items-center">
        <div className="flex flex-1 text-[14px]">{label}</div>
        <div className="flex justify-end flex-1 font-light text-[#666] text-[14px] text-right break-all">
          {value}
        </div>
      </li>
    );
  };
  const GlyphLower = () => {
    return (
      <ul className="flex flex-col">
        <GlyphListItem label="Value" value={detailPanelInfo.cValue} />
        <GlyphListItem label="Gas" value={detailPanelInfo.gasPaidGwei} />
        <GlyphListItem label="Time" value={format(new Date(detailPanelInfo.timeStamp), 'yyyy/MM/dd kk:mm:ss')} />
        <GlyphListItem
          label="Tx hash"
          value={detailPanelInfo.txnHash}
        />
        <GlyphListItem label="Block" value={detailPanelInfo.blockNumber} />
      </ul>
    );
  };

  return (
    <div className="bg-white rounded-3xl absolute right-10 rounded-b-none  bottom-0 w-[450px] z-50  border border-slate-500 ">
      <RenderIf isTrue={!isLoader && detailPanelInfo}>
        <GlyphUpper />
        <GlyphLower />
      </RenderIf>
      <RenderIf isTrue={isLoader}>
        <HexDetailLoader />
      </RenderIf>
    </div>
  );
};

export default HexDetailPanel;
