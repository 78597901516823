import { useState } from "react";
import { RenderIf } from "../../../../../../util/renderIf";
import { HexFilters } from "../../../../index.service";

const ContainerSidebarContent = ({
  hexTypeList,
  selectActiveHexType,
  selectHome,
  isHomeSelected,
  openSearchPanel,
  isSearchPanel
}) => {
  const [isFilterOpen, setFilterOpen] = useState(true);

  const toggleFilter = () => setFilterOpen(!isFilterOpen);

  /* JSX Element */
  const HexTypeItem = ({ text, img, active, index, filter }) => {
    return (
      <li
        onClick={() => {
          selectActiveHexType(index, filter);
        }}
        className={`flex justify-center items-center py-3 cursor-pointer hover:bg-backgroundDropdown hover:rounded ${
          active ? "bg-backgroundDropdown rounded" : ""
        }`}
      >
        <img src={img} alt={text} className="w-[30px] h-[30px]"></img>
        {/* <span>{text}</span> */}
      </li>
    );
  };

  const HexTypeList = () => {
    return hexTypeList.map((type, index) => {
      return (
        <HexTypeItem
          key={type.id}
          index={index}
          text={type.title}
          img={type.img}
          active={type.active}
          filter={type.filter}
        />
      );
    });
  };

  const HomeMenu = () => {
    return (
      <div
        onClick={() => {
          selectHome(HexFilters.home);
        }}
        className={`flex justify-center cursor-pointer hover:bg-backgroundDropdown hover:rounded py-3 ${
          isHomeSelected ? "bg-backgroundDropdown rounded" : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
          />
        </svg>
      </div>
    );
  };

  const SearchMenu = () => {
    return (
      <div
        className=" flex justify-center items-center  py-3 cursor-pointer hover:bg-backgroundDropdown rounded mt-3"
        onClick={openSearchPanel}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>
    );
  };

  const HexMenu = () => {
    return (
      <div className="mb-2 flex flex-col gap-3 mt-3">
        <div
          className="flex justify-center items-center cursor-pointer hover:bg-backgroundDropdown py-3 rounded"
          onClick={() => toggleFilter()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
            />
          </svg>
        </div>
        <RenderIf isTrue={isFilterOpen}>
          <div className="flex w-full flex-col">
            <HexTypeList />
          </div>
        </RenderIf>
      </div>
    );
  };

  return (
    <div className="flex flex-col pt-7">
      <HomeMenu />
      <RenderIf isTrue={!isSearchPanel}>
        <SearchMenu />
        <HexMenu />
      </RenderIf>
    </div>
  );
};

export default ContainerSidebarContent;
