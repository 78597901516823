import React, { useContext } from "react";
import ReactHexGridLayout from "../../react-grid-layout/index";
import { RenderIf } from "../../../../../util/renderIf";
import SkeletonHexLoader from "../../skeleton-hex-loader/skeleton-hex-loader";
import ContainerContentHeader from "./container-content-header/container-content-header";
import GlobalContext from "../../../context/global.context";
import noData from "../../../../../images/nodata/nodata.svg";
import HexDetailPanel from "../../hex-detail-panel/hex-detail-panel";

const ContainerContent = ({
  hexData,
  onClickHex,
  onChangeFilter,
  selectedFilter,
  onChangeTimeFilter,
  selectedTimeFilter,
  handlePaging,
  accountConnected,
  onClickConnectWallet,
  showShareModal,
  hexPanelOpen,
  currentGlyphId
}) => {
  const { isLoading, isUrlParamAndWalletNotConnected } =
    useContext(GlobalContext);

  /* JSX Elements */
  const NoData = () => {
    return (
      <div className="flex w-100 h-[500px] justify-center items-center flex-col">
        <img src={noData} alt={"No Data"} />
        <span className="text-[#767676] text-xl">No transactions to map</span>
      </div>
    );
  };

  return (
    <div className="w-full bg-slate-200  p-5 px-8">
      <RenderIf isTrue={isLoading}>
        <div className="w-full flex justify-center h-full items-center">
          <SkeletonHexLoader />
        </div>
      </RenderIf>

      <RenderIf isTrue={!isLoading}>
        <ContainerContentHeader
          showShareModal={showShareModal}
          onClickConnectWallet={onClickConnectWallet}
        />
        <RenderIf isTrue={Object.keys(hexData).length > 0}>
          <ReactHexGridLayout
            hexData={hexData}
            onClickHex={onClickHex}
            onChangeFilter={onChangeFilter}
            selectedFilter={selectedFilter}
            onChangeTimeFilter={onChangeTimeFilter}
            selectedTimeFilter={selectedTimeFilter}
            handlePaging={handlePaging}
            isUrlParamAndWalletNotConnected={isUrlParamAndWalletNotConnected}
            accountConnected={accountConnected}
          />
        </RenderIf>

        <RenderIf isTrue={Object.keys(hexData).length === 0}>
          <NoData />
        </RenderIf>

        <RenderIf isTrue={hexPanelOpen}>
          <HexDetailPanel glyphId={currentGlyphId} hexData={hexData} />
        </RenderIf>
      </RenderIf>
    </div>
  );
};

export default ContainerContent;
