import React, { useContext, useEffect, useRef, useState,memo } from "react";
import { HexGrid, Layout, Hexagon, Text, Pattern } from "react-hexgrid";
import "./react-grid-layout.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { getCoordinates } from "./index.service";
import PaginateArrow from "../paginate/index";
import { RenderIf } from "../../../../util/renderIf";
import GlobalContext from "../../context/global.context";
import horizontalSvgLegendImage from "../../../../images/legend/legend-img1.jpeg";

const ReactHexGridLayout = ({
  hexData,
  onClickHex,
  onChangeFilter,
  selectedFilter,
  selectedTimeFilter,
  handlePaging,
  accountConnected,
  isUrlParamAndWalletNotConnected,
}) => {
  const [isActive, setIsActive] = useState(false);
  let {
    layoutHexagonSizeX,
    layoutHexagonSizeY,
    layoutOriginX,
    layoutOriginY,
    layoutSpacing,
    viewboxHeight,
    viewboxMinX,
    viewboxMinY,
    viewboxWidth,
    hexes,
  } = hexData;

  const hexagonSize = {
    x: layoutHexagonSizeX ?? 15,
    y: layoutHexagonSizeY ?? 15,
  };

  if (!hexes) {
    hexes = [];
  }
  const { pageNumber, totalPageNumberCount, paginateDirection } =
    useContext(GlobalContext);

  const hexagonElement = useRef();

  useEffect(() => {
    const { hexes } = hexData;
    (hexes ?? []).map((item, index) => {
      //this will make the image not blurry. Current package does not provide straight forward procedure to include this.
      let pattern = document.getElementById(`PAT-${index}`);
      if (pattern) {
        pattern.setAttribute("width", "100%");
        pattern.setAttribute("height", "100%");
      }
    });
  }, [hexData]);

  useEffect(() => {
    //Iterating through all the svg child elements and accumulation the minX, minY maxX, maxY to find the scale level of SVG.
    const svg = document.querySelector("svg.grid");
    const { xMin, xMax, yMin, yMax } = [...svg.children].reduce((acc, el) => {
      const { x, y, width, height } = el.getBBox();
      if (!acc.xMin || x < acc.xMin) acc.xMin = x;
      if (!acc.xMax || x + width > acc.xMax) acc.xMax = x + width;
      if (!acc.yMin || y < acc.yMin) acc.yMin = y;
      if (!acc.yMax || y + height > acc.yMax) acc.yMax = y + height;
      return acc;
    }, {});
    const viewbox = `${xMin} ${yMin} ${xMax - xMin} ${yMax - yMin}`;
    svg.setAttribute("viewBox", viewbox);
  });

  useEffect(() => {
    // Transitions
    // const div = hexagonElement.current;
    // div.style.animation = "";
    // if (paginateDirection === "right") {
    //   div.style.animation = "nextPage 1s forwards";
    // } else {
    //   div.style.animation = "prevPage 1s forwards";
    // }
    // return () => {
    //   div.style.animation = "1s linear";
    // };
  }, [hexData]);

  function PageNumber() {
    return (
      <div className="flex gap-1">
        <span>{pageNumber}</span>/<span>{totalPageNumberCount}</span>
      </div>
    );
  }

  function Legend() {
    return (
      <div className="flex flex-1 absolute left-0 bottom-0 w-[400px]">
        <RenderIf isTrue={isActive}>
          <div className="absolute bottom-10">
            <img
              src={horizontalSvgLegendImage}
              alt={"Legend"}
              className="h-[400px]"
            />
          </div>
        </RenderIf>
        <div
          className="flex flex-row-reverse items-center cursor-pointer absolute bottom-0"
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
        >
          <span className="hover:underline">Legend</span>
        </div>
      </div>
    );
  }

  function Paginate() {
    return (
      <div className="flex items-center gap-3">
        <PaginateArrow
          isDisabled={pageNumber === 1}
          isRight={false}
          handleClick={() => handlePaging("left")}
        />
        <PageNumber />
        <PaginateArrow
          isDisabled={pageNumber === totalPageNumberCount}
          isRight={true}
          handleClick={() => handlePaging("right")}
        />
      </div>
    );
  }

  function PaginateLegendWrapper() {
    return (
      <div className="flex justify-center items-center mt-7 relative">
        <Legend />
        <Paginate />
      </div>
    );
  }

  return (
    <React.Fragment>
      <TransformWrapper
        wheel={{ step: 0.01 }}
        maxScale={4}
        centerOnInit={true}
        doubleClick={{ disabled: true }}
        panning={{ disabled: false }}
        className="w-full"
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <aside className="zoom-btn-wrapper">
              <IconButton onClick={() => zoomIn()}>
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={() => zoomOut()}>
                <ZoomOutIcon />
              </IconButton>
            </aside>
            <TransformComponent>
              <div ref={hexagonElement} className="grid-container">
                <HexGrid
                  width={"100%"}
                  height={"100%"}
                  viewBox={`${viewboxMinX ?? 0} ${viewboxMinY ?? 0} ${
                    viewboxWidth ?? 100
                  } ${viewboxHeight ?? 100}`}
                >
                  {/* Main grid with bit hexagons, all manual */}
                  <Layout
                    size={hexagonSize}
                    flat={false}
                    spacing={layoutSpacing}
                    origin={{ x: layoutOriginX, y: layoutOriginY }}
                  >
                    {(hexes ?? []).map((hex, index) => {
                      const {
                        borderColorHex,
                        borderColorWidth,
                        fillText,
                        fillURL,
                        altText,
                      } = hex;
                      let { q, r, s } = getCoordinates(
                        selectedFilter,
                        hex,
                        selectedTimeFilter
                      );

                      return (
                        <>
                          <Hexagon
                            onClick={() => {
                              onClickHex(hex);
                            }}
                            q={q}
                            r={r}
                            s={s}
                            cellStyle={{
                              stroke: borderColorHex,
                              strokeWidth: borderColorWidth,
                            }}
                            fill={`PAT-${index}`}
                            id={`grid-identifier-${index}`}
                          >
                            nik
                            <title id="unique-id">{altText}</title>
                            {fillText && <Text>{fillText}</Text>}
                          </Hexagon>
                          <RenderIf isTrue={fillURL}>
                            <Pattern
                              id={`PAT-${index}`}
                              link={fillURL}
                              size={hexagonSize}
                            />
                          </RenderIf>
                        </>
                      );
                    })}
                  </Layout>
                </HexGrid>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      <PaginateLegendWrapper />
    </React.Fragment>
  );
};

export default ReactHexGridLayout;
