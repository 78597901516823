const SidebarLoader = () => {
  return (
    <div className="flex flex-col animate-pulse h-full w-full   w-100 items-center">
      <div className="flex flex-col flex-1">
        <div className="flex flex-col w-full gap-4 justify-center items-center mb-7">
          <div className="h-20 w-20 bg-slate-300 rounded-full"></div>
        </div>
        <div className="flex flex-col w-full gap-4 justify-center mb-5">
          <div className="flex flex-col gap-4">
            <div class="h-2 bg-slate-300 rounded "></div>
            <div class="h-2 bg-slate-300 rounded "></div>
            <div class="h-2 bg-slate-300 rounded "></div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col flex-1">
        <div class="h-10 w-full bg-slate-300 rounded mb-5"></div>
        <div class="h-10 w-full bg-slate-300 rounded mb-4"></div>
        <div class="h-10 w-full bg-slate-300 rounded mb-5"></div>
        <div class="h-10 w-full bg-slate-300 rounded mb-4"></div>
        <div class="h-10 w-full bg-slate-300 rounded mb-4"></div>
      </div>
      <div className="flex flex-col items-center w-full flex-1 justify-end gap-4">
        <div class="h-10 w-10 bg-slate-300 rounded-full"></div>
        <div class="h-10 w-10 bg-slate-300 rounded-full"></div>
      </div>
    </div>
  );
};

export default SidebarLoader;
