import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import profile from "../../../../images/profile.jpeg";
import { RenderIf } from "../../../../util/renderIf";
import GlobalContext from "../../context/global.context";
import Web3 from "web3";
import { getUSDValueTo1ETH } from "./service/index.service";

export const ConnectedAccount = ({
  onClickDisconnect,
  address,
  isValidNetwork,
}) => {
  const { hexData, library } = useContext(GlobalContext);
  const [isAddressVisible, setAddressVisible] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalanceUSD, setWalletBalanceUSD] = useState(0);

  useEffect(() => {
    console.log("Nikgil");
    _getBalance();
  }, []);

  var truncate = function (fullStr, strLen, separator) {
    if (fullStr) {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || "...";

      var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

      return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
      );
    }
  };

  const _getBalance = async () => {
    const web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(address);
    const formatedBalance = web3.utils.fromWei(balance, "ether");
    const ethToUsdValue = await getUSDValueTo1ETH();
    const value = ethToUsdValue.USD * formatedBalance;
    const usdValue = parseFloat(value.toFixed(4));
    setWalletBalanceUSD(usdValue);
    setWalletBalance(formatedBalance);
  };

  const ConnectedWalletENS = () => {
    return (
      <div
        onMouseEnter={() => setAddressVisible(true)}
        className="bg-white  py-3 px-4 flex justify-between rounded-full items-center w-64  cursor-pointer"
      >
        <div className="flex gap-2 items-center">
          <div className="h-5 w-5 bg-slate-700  rounded-full"></div>
          {truncate(address, 12)}
        </div>

        <div className="flex">${walletBalanceUSD}</div>
      </div>
    );
  };

  const ConnectedWalletAddress = () => {
    return (
      <div
        onMouseLeave={() => setAddressVisible(false)}
        className="bg-white  py-3 px-4 flex justify-between rounded-full items-center w-64  cursor-pointer"
      >
        <div className="flex gap-2 items-center">
          <div className="h-5 w-5 bg-slate-700  rounded-full"></div>
          {truncate(address, 12)}
        </div>

        <div className="flex">{walletBalance} ETH</div>
      </div>
    );
  };

  const LogoutButton = () => {
    return (
      <div
        className="bg-black h-9 w-9 rounded-full  flex justify-center items-center ml-2 cursor-pointer"
        onClick={onClickDisconnect}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#fff"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
          />
        </svg>
      </div>
    );
  };

  return (
    <aside className="profile-block primary-btn flex items-center">
      <RenderIf isTrue={address && address.length > 0}>
        {/* <ConnectedWalletOld /> */}
        <RenderIf isTrue={isAddressVisible}>
          <ConnectedWalletAddress />
        </RenderIf>
        <RenderIf isTrue={!isAddressVisible}>
          <ConnectedWalletENS />
        </RenderIf>
      </RenderIf>
      <LogoutButton />
    </aside>
  );
};

// const ConnectedWalletOld = () => {
//   return (
//     <Button variant="contained" onClick={onClickDisconnect}>
//       <p>
//         <span className="profile-id">
//           <img src={profile} />

//           <span className="ethereum-num">
//             <span
//               className={`profile-name ${isValidNetwork ? "connected" : ""}`}
//             >
//               {isValidNetwork ? "Connected" : "Invalid Network"}
//             </span>
//             {/* <span className="profile-name connected"> Connected </span> */}
//             <span className="eth-icon-text">
//               <i>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 48 48"
//                   width="48px"
//                   height="48px"
//                 >
//                   <path fill="#9fa8da" d="M11 24L25 2 39 24 25 32z" />
//                   <path fill="#7986cb" d="M25 2L39 24 25 32z" />
//                   <path fill="#9fa8da" d="M11 27L25 35 39 27 25 46z" />
//                   <path
//                     fill="#7986cb"
//                     d="M25 35L39 27 25 46zM11 24L25 18 39 24 25 32z"
//                   />
//                   <path fill="#5c6bc0" d="M25 18L39 24 25 32z" />
//                 </svg>
//               </i>{" "}
//               {truncate(address, 12)}
//             </span>
//           </span>
//         </span>
//         <span className="log-out" title="Disconnect">
//           <svg
//             width="24"
//             height="25"
//             viewBox="0 0 24 25"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               fillRule="evenodd"
//               clipRule="evenodd"
//               d="M11.655 8.9671C11.7526 8.86947 11.9109 8.86947 12.0085 8.9671L12.9445 9.90306C12.9914 9.94994 13.0177 10.0135 13.0177 10.0798C13.0177 10.1461 12.9914 10.2097 12.9445 10.2566L11.613 11.5881H19.1133C19.2514 11.5881 19.3633 11.7 19.3633 11.8381V13.1617C19.3633 13.2998 19.2514 13.4117 19.1133 13.4117H11.6132L12.9445 14.743C12.9914 14.7899 13.0177 14.8535 13.0177 14.9198C13.0177 14.9861 12.9914 15.0497 12.9445 15.0966L12.0085 16.0325C11.9616 16.0794 11.8981 16.1057 11.8318 16.1057C11.7655 16.1057 11.7019 16.0794 11.655 16.0325L8.76703 13.1446C8.41094 12.7885 8.41094 12.2111 8.76703 11.8551L11.655 8.9671Z"
//               fill="#5A6277"
//             ></path>
//             <path
//               fillRule="evenodd"
//               clipRule="evenodd"
//               d="M14.25 6.5L6.25 6.5L6.25 18.5L14.25 18.5V20.5H6.25H5.25H4.25L4.25 4.5H5.25H6.25H14.25V6.5Z"
//               fill="#5A6277"
//             ></path>
//           </svg>
//         </span>
//       </p>
//     </Button>
//   );
// };
