export const ChainId = {
  ethereumMainnet: 1,
  ropstenTestNet: 3,
  kovanTestNet: 42,
  rinkebyTestNet: 4,
  goerliTestNet: 5,
};

export const Providers = {
  metaMask: "injected",
  walletConnect: "walletConnect",
};
