import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import { PageScreen } from "./page-name";
import DashboardPage from "../page/dashboard";

export const MainRouter = () => {
  return (
    <div className="wrapper">
      <React.Fragment>
        <Router>
          <Routes>
            <Route
              exact
              path={PageScreen.dashboard}
              element={<DashboardPage />}
            />
            <Route
              path="*"
              // element={<Navigate to={PageScreen.dashboard} replace />}
              element={<DashboardPage />}
            />
          </Routes>
        </Router>
      </React.Fragment>
    </div>
  );
};
