import { useContext } from "react";
import { RenderIf } from "../../../../../../util/renderIf";
import GlobalContext from "../../../../context/global.context";
import { ConnectedAccount } from "../../../connected-account/index";

const ContainerContentHeader = ({ onClickConnectWallet }) => {
  const {
    openShareModal,
    active,
    account,
    onClickDisconnect,
    isValidNetwork,
    connectToWallet,
    hexData,
  } = useContext(GlobalContext);

  const BaseImageClass =
    "flex bg-[#fff] hover:bg-white  rounded-full w-12 h-12 cursor-pointer  justify-center items-center";

  return (
    <div className="flex w-full justify-end gap-4 items-center  mb-4">
        <div className={BaseImageClass} onClick={openShareModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
            />
          </svg>
        </div>

      <RenderIf isTrue={active}>
        <ConnectedAccount
          onClickDisconnect={onClickDisconnect}
          address={account}
          isValidNetwork={isValidNetwork}
          connectToWallet={connectToWallet}
        />
      </RenderIf>
      <RenderIf isTrue={!active}>
        <div className={BaseImageClass} onClick={onClickConnectWallet}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
            />
          </svg>
        </div>
      </RenderIf>
    </div>
  );
};

export default ContainerContentHeader;
