import logo from "../../../../../../images/logo.svg";

const ContainerSidebarHeader = ({ hexData }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 ">
      <img src={logo} alt={"logo"} ></img>
      <div className="flex flex-col justify-center">
        <span className="text-handleColor text-2xl leading-7 flex justify-center">
          {hexData?.dotEarthHandle}
        </span>
        <span className="text-black text-lg leading-5 flex justify-start">.earth</span>
        <span className="text-black  text-lg leading-5 flex justify-start">.eth</span>
      </div>
    </div>
  );
};

export default ContainerSidebarHeader;
